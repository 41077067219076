import { createMenu } from '@/libs/util.menu';
import {getMatchList,getSiteUrl} from "@/api/data.js";

// <a-icon :component="defaultSvg" style="font-size:14px;"/>
export default {
  render (h) {
    return <div class="application-box">
      { 
        this.matchList.length > 0 ? <div class="elmenu-box">
          <div class="title">业务应用</div>
          <a-menu
            mode="inline"
            ref="menu"
            selectedKeys={ this.selectedKeys }
            onclick={ this.goModelYun }
            onselect={ this.getselectedMenu }>
            { this.matchList.map(menu =>createMenu.call(this, h, menu))}
          </a-menu>
        </div>:null
      }
    </div>
  },
  data () {
    return {
      teamInfo:'',
      matchList:[],
      selectedKeys:[],
    }
  },
  mounted () {
    this.teamInfo = JSON.parse(localStorage.getItem('teamInfo'));
    this.getMatchList();
  },
  methods: {
    changeSelectMenu(){
      this.selectedKeys = [];
    },
    getselectedMenu(item){
      this.selectedKeys = item.selectedKeys;
    },
    getMatchList(){
      getMatchList({
        team_id:this.teamInfo.team_id,
        matching_userid:this.teamInfo.matching_userid
      }).then(res => {
        if(res.code === 1){
          this.matchList = [];
          res.data.forEach(item => {
            item.apps.forEach(val => {
              val.icon_name = val.icon_name || 'tf-icon-operation';
              this.matchList.push(val);
            })
          });
        }
      })
    },
    goModelYun(item){
      this.$emit('changeItem');
      let urlData;
      if(item.keyPath.length > 1){
        urlData = item.keyPath[1] + '#/form/' + item.keyPath[0];;
      }else{
        urlData = item.key;
      }
      getSiteUrl({
        team_id:this.teamInfo.team_id,
        matching_userid:this.teamInfo.matching_userid,
        url:'/dashboard/app/'+urlData,
      }).then(res => {
        if(res.code === 1){
          window.open(`${res.data.path}`, '_blank');
        }
      })
    },
  }
}

