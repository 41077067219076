/**
 * @description 创建菜单
 * @param {Function} h createElement
 * @param {Object} menu 菜单项
 */
import {svgList} from '@/assets/svg/svg.js';
export function elMenuItem (h, menu) {
  let id = menu._id || '';
  if(id){
    return <a-menu-item
      key={ menu._id }
      index={ menu._id }>
      <a-icon component={ svgList[menu.icon_name]  } style="font-size:14px;"/>
      { menu.appname}
    </a-menu-item> 
  }else{
    return <a-menu-item
      key={ menu.formid }
      index={ menu.formid }>
      { menu.title}
    </a-menu-item> 
  }
}

/**
 * @description 创建子菜单
 * @param {Function} h createElement
 * @param {Object} menu 菜单项
 */
export function elSubmenu (h, menu) {
  return <a-sub-menu
    key={ menu._id }
    index={ menu._id }>
    <span slot="title"><a-icon component={ svgList[menu.icon_name] } style="font-size:14px;"/>{ menu.appname}</span>
    { menu.form_list.map(child => createMenu.call(this, h, child)) }
  </a-sub-menu>
}

/**
 * @description 在组件中调用此方法渲染菜单项目
 * @param {Function} h createElement
 * @param {Object} menu 菜单项
 */
export function createMenu (h, menu) {
  if (menu.form_list === undefined || menu.form_list.length === 0) return elMenuItem.call(this, h, menu)
  return elSubmenu.call(this, h, menu)
}
