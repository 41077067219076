<template>
    <!-- 下面 主体 -->
    <div class="d2-theme-container">
      <div class="menu-box">
        <div class="application-box" v-if="appList.length > 0">
          <div class="title">数据驾驶舱</div>
          <div class="list">
            <div class="menu" v-for="(item,index) in appList" :key="item.id">
              <div :class="['application',i === index ? 'selected' : '']" @click="goAiYun(item,index)">
                <a-icon :component="svgList[item.icon_name]" style="font-size:14px;"/>
                <span class="txt overflow">{{item.app_name}}</span>
              </div>
            </div>
          </div>
        </div>
        <MenuSide @changeItem="changeItem" ref="menu"/>
      </div>
    </div>    
</template>

<script>
import {getAppList} from "@/api/data.js";
import cookies from "@/libs/util.cookies.js";
import MenuSide from './menu-side'
import {svgList} from '@/assets/svg/svg.js';
export default {
  components:{MenuSide},
  data(){
    return {
      matchList:[],
      appList:[],
      userInfo:'',
      platformdata:'',
      teamInfo:'',
      svgList:svgList,
      i:-1,
    }
  },
  computed:{
    userinfo(){
      return this.$store.state.userinfo;
    },
    platformData(){
      return this.$store.state.platformData;
    }
  },
  watch:{
    userinfo:{
      handler(newVal,oldVal){
        this.userInfo = newVal;
      },
      deep:true,
      immediate:true,
    },
    platformData:{
      handler(newVal,oldVal){
        this.platformdata = newVal;
      },
      deep:true,
      immediate:true,
    }
  },
  mounted(){
    this.getAppList();
  },
  methods:{
    changeItem(){
      this.i = -1;
    },
    goAiYun(item,index){
      this.i = index;
      let token = cookies.get('token');
      this.$nextTick(() => {
        this.$refs.menu.changeSelectMenu();
      })
      if(item){
        if(this.userInfo){
          if(item.app_url.indexOf('?') != -1){
            window.open(`${item.app_url}&uid=${this.userInfo.uid}&token=${token}&platform_name=${this.platformdata.platform_name}&platform_id=${this.userInfo.platform_id}&loginStatu=${this.teamInfo.loginStatu}&type=${this.userInfo.type}`, '_blank');
          }else{
            window.open(`${item.app_url}?uid=${this.userInfo.uid}&token=${token}&platform_name=${this.platformdata.platform_name}&platform_id=${this.userInfo.platform_id}&loginStatu=${this.teamInfo.loginStatu}&type=${this.userInfo.type}`, '_blank');
          }
        }
      }
    },
    getAppList(){
      getAppList().then(res => {
        if(res.code === 1){
          this.appList = res.data.map(item =>{
            item.icon_name = item.icon_name || 'tf-icon-operation';
            return item;
          });
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.d2-theme-container{
  min-width:216px;
  width: 15%;
  max-width: 240px;
  .menu-box{
    width:100%;
    height:100%;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    font-size:12px;
    line-height:22px;
    overflow-y: auto;
    overflow-x: hidden;
    .application-box{
      border-top: 1px solid #F0F0F0;
    }
    .application-box:first-child{
      border:0;
    }
    .title{
      padding-left:24px;
      line-height: 40px;
      color: #000;
    }
    .list{
      padding-left:24px;
      box-sizing: border-box;
      .list{
        position: relative;
      }
      .application{
        color: rgba(0, 0, 0, 0.85);
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        line-height:40px;
        margin-bottom: 9px;
        .txt{
          margin: 0 12px;
        }
      }
      .application:hover{
        color: #1890ff;
      }
      .selected{
        background-color: #e6f7ff;
        color: #1890ff;
      }
      .selected::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        border-right: 3px solid #1890ff;
        opacity: 1;
        transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
        content: '';
      }
    }
  }
  .d2-theme-container-main{
    flex:1;
    .d2-theme-container-main-body{
      height:100%;
    }
  }
}
</style>